import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Check, ChevronsUpDown, X } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { groupBy } from 'lodash'
import { cn } from '@/lib/utils'
import { useTranslation } from 'react-i18next'
import { usePageInfo, useRealms } from '../config/queries'

interface Props {
  setRealm: (value: wowaudit.Realm | undefined) => void
  realm?: wowaudit.Realm
  inModal?: boolean
  isClearable?: boolean
  className?: string
}

const RealmSelect = ({
  setRealm,
  realm,
  className,
  isClearable,
  inModal = false,
}: Props) => {
  const { t } = useTranslation()
  const {
    data: { gameVersion },
  } = usePageInfo()
  const { data: realms } = useRealms()
  const [open, setOpen] = useState(false)
  const [term, setTerm] = useState('')
  const minimumSearchLength = gameVersion === 'live' ? 2 : 0

  const realmsByRegion = useMemo(() => {
    if (realms) {
      return Object.entries(groupBy(realms, (realm) => realm.region))
    } else {
      return []
    }
  }, [realms])

  return (
    <Popover open={open} onOpenChange={setOpen} modal={inModal}>
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          role='combobox'
          aria-expanded={open}
          className={`w-[12rem] justify-between bg-background hover:bg-background border border-input ${className}`}
        >
          <span
            className={`${realm ? '' : 'text-muted-foreground'} font-normal`}
          >
            {realm ? (
              <span className='max-w-[8.3rem] truncate block'>
                <span className='text-muted-foreground mr-1 text-xs'>
                  {realm.region}
                </span>{' '}
                {realm.name}
              </span>
            ) : isClearable ? (
              'Any realm'
            ) : (
              'Select realm'
            )}
          </span>
          {realm && isClearable ? (
            <X
              className='ml-2 mr-[-0.3rem] opacity-50 hover:opacity-100 transition-opacity p-1'
              onClick={(e) => {
                e.preventDefault()
                setRealm(undefined)
              }}
            />
          ) : (
            <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className='w-[12rem] p-0'
        style={{ pointerEvents: 'auto' }}
      >
        <Command
          filter={(value, search) => {
            if (value.toLowerCase().includes(search.toLowerCase())) return 1
            return 0
          }}
        >
          <CommandInput
            placeholder={t('realm-select.search-placeholder')}
            value={term}
            onValueChange={setTerm}
          />
          <CommandList className='max-h-[12rem]'>
            {term.length >= minimumSearchLength && (
              <CommandEmpty>No realm found.</CommandEmpty>
            )}
            {(term.length >= minimumSearchLength
              ? realmsByRegion
              : realm
              ? ([[realm.region, [realm]]] as [string, wowaudit.Realm[]][])
              : []
            ).map(([region, realms]) => (
              <CommandGroup key={region} heading={region}>
                {realms.map((realmOption) => (
                  <CommandItem
                    key={realmOption.id}
                    value={realmOption.nameWithRegion}
                    onSelect={() => {
                      setRealm(realm === realmOption ? undefined : realmOption)
                      setOpen(false)
                    }}
                  >
                    <div className='flex items-center'>
                      <Check
                        className={cn(
                          'mr-2 h-4 w-4',
                          realmOption === realm ? 'opacity-100' : 'opacity-0',
                        )}
                      />
                      {realmOption.name}
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default RealmSelect
