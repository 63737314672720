import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../config/context/app'
import LogoLight from 'public/images/logo-light.svg?react'
import LogoDark from 'public/images/logo-dark.svg?react'

import {
  ChevronDown,
  LogOut,
  Monitor,
  Moon,
  Search,
  Star,
  Sun,
  UserRoundCog,
  UsersRound,
} from 'lucide-react'

import { CharacterSwitcher } from '.'

import { useTheme } from '@/components/theme-provider'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu'
import { Button, buttonVariants } from '@/components/ui/button'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu'
import SearchBar from './SearchBar'
import CharacterAvatar from './CharacterAvatar'
import { usePageInfo } from '../config/queries'
import { useActionable, imageUrl, useSubscription } from '../helpers'
import PremiumModal from './PremiumModal'

const Header = () => {
  const {
    data: { user, gameVersion },
  } = usePageInfo()
  const { currentCharacter } = useContext(AppContext)
  const [switchingCharacter, setSwitchingCharacter] = useState(false)
  const [searching, setSearching] = useState(false)
  const { t } = useTranslation()
  const { theme, setTheme, currentScheme } = useTheme()
  const actionable = useActionable(user)

  const Logo = currentScheme === 'dark' ? LogoLight : LogoDark

  const { subscriptionColor, subscriptionName, subscriptionActive } =
    useSubscription(user)

  return (
    <header className='sticky top-0 z-50 w-full border-b bg-muted'>
      <div className='container flex h-14 items-center justify-between'>
        <div className='flex items-center gap-0.5 justify-between'>
          <div className='hidden md:flex'>
            <Link to='/' className='mr-2'>
              <Logo className='h-12 w-[10.7rem]' color='hsl(var(--primary))' />
            </Link>
          </div>

          <NavigationMenu delayDuration={100}>
            <NavigationMenuList>
              <NavigationMenuItem className='mr-8'>
                <NavigationMenuTrigger className='text-md' triggerMode='click'>
                  {t(`game-versions.${gameVersion}.name`)}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className='grid gap-2 p-4 w-[21rem]'>
                    {['live', 'classic_progression', 'classic_era'].map(
                      (version) => (
                        <li className='row-span-3' key={version}>
                          <NavigationMenuLink asChild>
                            <a
                              className={`flex gap-4 ${
                                version === gameVersion ? 'bg-primary' : ''
                              } group items-center select-none rounded-md bg-gradient-to-b from-muted/75 to-muted/25 p-3 no-underline outline-none`}
                              href={`http${
                                location.host.includes('local') ? '' : 's'
                              }://${
                                {
                                  live: '',
                                  classic_era: 'vanilla.',
                                  classic_progression: 'classic.',
                                }[version]
                              }${location.host
                                .replace('live.', '')
                                .replace('classic.', '')
                                .replace('vanilla.', '')}`}
                            >
                              <img
                                className='w-auto h-auto max-w-[7rem]'
                                src={imageUrl(
                                  `game-versions/${version}-logo.png`,
                                )}
                              />
                              <h3
                                className={`text-[1.2rem] font-bold leading-tight ${
                                  version === gameVersion ? '' : 'opacity-30'
                                } group-hover:opacity-100 transition-opacity duration-300`}
                              >
                                {t(`game-versions.${version}.name`)}
                              </h3>
                            </a>
                          </NavigationMenuLink>
                        </li>
                      ),
                    )}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle()}
                  asChild
                >
                  <Link to='/dashboard'>{t('header.personal')}</Link>
                </NavigationMenuLink>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle()}
                  asChild
                >
                  <Link to='/dashboard'>{t('header.team')}</Link>
                </NavigationMenuLink>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle()}
                  asChild
                >
                  <Link to='/dashboard'>{t('header.events')}</Link>
                </NavigationMenuLink>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle()}
                  asChild
                >
                  <Link to='/dashboard'>{t('header.wishlist')}</Link>
                </NavigationMenuLink>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
        </div>

        <div>
          <CharacterSwitcher
            open={switchingCharacter}
            setOpen={setSwitchingCharacter}
          />

          <div className='ml-4 flex items-center'>
            <Search
              size={18}
              className='mr-4 cursor-pointer p-2 w-[2.1rem] h-[2.1rem]'
              onClick={() => setSearching(true)}
            />

            {searching && (
              <SearchBar
                type='dialog'
                placeholder={t('header.search-placeholder')}
                setOpen={setSearching}
              />
            )}

            {user ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <span className='flex gap-1 items-center cursor-pointer'>
                    <CharacterAvatar
                      className={`mr-1.5 h-10 w-10`}
                      character={currentCharacter}
                    />

                    {currentCharacter?.name || user.battletag}

                    {subscriptionActive ? (
                      <Star
                        width={16}
                        className={`ml-1 ${subscriptionColor.text}`}
                      />
                    ) : null}

                    <ChevronDown
                      width={12}
                      className='ml-0.5 mt-1 opacity-70'
                    />
                  </span>
                </DropdownMenuTrigger>
                <DropdownMenuContent align='end'>
                  <DropdownMenuLabel>
                    {currentCharacter ? (
                      <>
                        <span className='flex items-center justify-between relative'>
                          <span className='min-w-[7.5rem]'>
                            <a href={currentCharacter.path}>
                              {currentCharacter.name}
                            </a>
                          </span>
                          <span className='opacity-70 text-[0.7rem] mr-0.5 font-normal'>
                            {user.battletag}
                          </span>
                        </span>
                        <div className='opacity-70 font-normal text-[0.7rem] mt-[-0.2rem]'>
                          {currentCharacter.realm.name} (
                          <span>{currentCharacter.realm.region}</span>)
                        </div>
                      </>
                    ) : (
                      <span>{user.battletag}</span>
                    )}
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={() => setSwitchingCharacter(true)}>
                    <span className='flex gap-2 items-center'>
                      <UsersRound width={14} />
                      {currentCharacter
                        ? t('header.switch-character')
                        : t('header.set-character')}
                    </span>
                  </DropdownMenuItem>
                  <Link to='/profile'>
                    <DropdownMenuItem className='flex gap-2 items-center'>
                      <UserRoundCog width={14} />
                      {t('header.profile')}
                    </DropdownMenuItem>
                  </Link>
                  {user ? (
                    <React.Fragment>
                      <DropdownMenuSeparator />
                      <DropdownMenuLabel>
                        <div className='flex justify-between'>
                          <span>{t('shared.premium')}</span>
                          <PremiumModal>
                            <Button
                              variant='secondary'
                              size='xs'
                              className='text-xs'
                            >
                              {t(
                                subscriptionActive
                                  ? 'premium.change-plan'
                                  : 'shared.subscribe',
                              )}
                            </Button>
                          </PremiumModal>
                        </div>

                        <div className='mt-1'>
                          <span
                            className={`font-normal ${subscriptionColor.text}`}
                          >
                            {subscriptionName}
                          </span>
                        </div>
                      </DropdownMenuLabel>
                    </React.Fragment>
                  ) : null}

                  <DropdownMenuSeparator />
                  <DropdownMenuLabel>
                    <span className='flex items-center justify-between'>
                      <span>{t('header.change-theme')}</span>
                      <span className='opacity-70 text-[0.7rem] mr-0.5 font-normal'>
                        {t(`header.${theme}`)}
                      </span>
                    </span>
                    <div className='flex gap-1 items-center'>
                      {(['light', 'dark', 'system'] as const).map((option) => {
                        const Icon = {
                          light: Sun,
                          dark: Moon,
                          system: Monitor,
                        }[option]
                        return (
                          <div
                            key={option}
                            onClick={() => setTheme(option)}
                            className={`mt-1.5 cursor-pointer flex-grow ${buttonVariants(
                              {
                                variant:
                                  option === theme ? 'default' : 'secondary',
                                size: 'sm',
                              },
                            )}`}
                          >
                            <Icon width={18} />
                          </div>
                        )
                      })}
                    </div>
                  </DropdownMenuLabel>

                  <DropdownMenuSeparator />

                  <a href='/logout'>
                    <DropdownMenuItem className='flex gap-2 items-center'>
                      <LogOut width={14} />
                      {t('header.log-out')}
                    </DropdownMenuItem>
                  </a>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <div className='flex items-center gap-1'>
                <span className='mr-2 text-sm'>{t('header.log-in-with')}</span>
                <a
                  href='/auth/bnet'
                  className={buttonVariants({ variant: 'outline' })}
                >
                  <img
                    src={imageUrl('bnet-logo-blue.svg')}
                    className='w-5 mr-2'
                  />
                  Battle.net
                </a>
                <a
                  href='/auth/google_oauth2'
                  className={buttonVariants({ variant: 'outline' })}
                >
                  <img src={imageUrl('google-logo.svg')} className='w-5 mr-2' />
                  Google
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      {actionable ? (
        <div className='absolute top-14 w-[100vw] bg-yellow-500'>
          <div className='flex items-center justify-between container h-8'>
            <span className='dark:text-muted text-sm font-bold'>
              {t('profile.requires-action-warning')}
              {window.location.pathname !== '/profile'
                ? ` ${t('profile.visit-profile')}`
                : ''}
            </span>
            {window.location.pathname !== '/profile' ? (
              <Link to='/profile'>
                <Button variant='outline' size='xs'>
                  {t('profile.profile')}
                </Button>
              </Link>
            ) : null}
          </div>
        </div>
      ) : null}
    </header>
  )
}

export default Header
