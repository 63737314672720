import {
  EquippedItem,
  LegacyPledgeTier,
  SubscriptionTier,
} from '../../types/enums'
import { toast } from 'sonner'
import axios, { AxiosError } from 'axios'
import { TFunction } from 'i18next'
import { getPendingJobById } from '../api/job'
import { imageUrl } from './hooks'

export const wowheadUrl = (
  gameVersion: wowaudit.PageInfo['gameVersion'],
  item?: EquippedItem,
  id?: number,
) => {
  const gameVersionMap = {
    live: '',
    classic_era: 'classic/',
    classic_progression: 'wotlk/',
  }

  if (item) {
    return `https://wowhead.com/${gameVersionMap[gameVersion]}item=${
      item.id
    }&bonus=${item.bonus_ids.join(':')}&gems=${item.sockets
      .map((socket) => socket.gem)
      .join(':')}&ench=${item.enchant?.id}`
  } else if (id) {
    return `https://wowhead.com/${gameVersionMap[gameVersion]}item=${id}`
  }
}

export const mediaUrl = (
  character: wowaudit.Character | undefined,
  type: 'avatar' | 'main-raw',
) => {
  const extensionByType = {
    avatar: 'jpg',
    'main-raw': 'png',
  }

  if (character && character.mediaZone) {
    return `https://render.worldofwarcraft.com/${character.realm.region.toLowerCase()}/character/${
      character.realm.slug
    }/${character.mediaZone}/${character.profileId.split('-')[1]}-${type}.${
      extensionByType[type]
    }`
  } else if (type === 'avatar') {
    return imageUrl('bnet-temp.png')
  } else if (type === 'main-raw') {
    return imageUrl('avatar-fallback.png')
  }
}

export const getErrorDetails = (
  error: Error | AxiosError | string,
  t: TFunction,
) => {
  if (typeof error === 'string') {
    return { title: t('errors.custom'), description: t(error) }
  } else if (axios.isAxiosError(error) && error.response) {
    const isCustomString =
      t(error.response.data.description) === error.response.data.description

    return {
      title: t(`errors.${error.response.data.error}`),
      description: isCustomString
        ? error.response.data.description
        : t(error.response.data.description),
    }
  } else {
    return {
      title: t('errors.browser_error'),
      description: t('errors.fallback_description'),
    }
  }
}

export const displayErrorToast = (
  error: Error | AxiosError | string,
  t: TFunction,
) => {
  const textToDisplay = getErrorDetails(error, t)
  return toast.error(textToDisplay.title, {
    description: textToDisplay.description,
  })
}

export const awaitPendingJob = async (
  jobId: string,
  callback: (result: wowaudit.JobStatus) => void,
) => {
  setTimeout(async () => {
    const pendingJob = await getPendingJobById(jobId)
    console.log(pendingJob)

    if (['queued', 'working'].includes(pendingJob.status)) {
      awaitPendingJob(jobId, callback)
    } else {
      if (
        ['failed', 'interrupted'].includes(pendingJob.status) &&
        !pendingJob.error
      ) {
        pendingJob.error = `Your request ${pendingJob.status} for an unknown reason. Please try again.`
      }

      callback(pendingJob)
    }
  }, 1000)
}

// We have to hardcode the class names, Tailwind doesn't recognise
// class names that are composed dynamically at runtime
export const premiumColors: {
  [key in LegacyPledgeTier | SubscriptionTier]: { text: string; border: string }
} = {
  Free: { text: 'opacity-70 italic', border: 'border-transparent' },
  Bronze: {
    text: 'text-subscription-bronze',
    border: 'border-subscription-bronze',
  },
  Silver: {
    text: 'text-subscription-silver',
    border: 'border-subscription-silver',
  },
  Gold: { text: 'text-subscription-gold', border: 'border-subscription-gold' },
  Platinum: {
    text: 'text-subscription-platinum',
    border: 'border-subscription-platinum',
  },
  Rare: { text: 'text-quality-rare', border: 'border-quality-rare' },
  Epic: { text: 'text-quality-epic', border: 'border-quality-epic' },
  Legendary: {
    text: 'text-quality-legendary',
    border: 'border-quality-legendary',
  },
}

export * from './hooks'
