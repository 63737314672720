import React from 'react'
import { Main } from '../../components'
import { Navigate, useSearchParams } from 'react-router-dom'
import { AuthStrategy } from 'app/frontend/types/enums'
import { useTranslation } from 'react-i18next'
import { usePageInfo } from '../../config/queries'
import { imageUrl } from '../../helpers'

const infoByStrategy = {
  discord: { logo: 'discord-logo-blue.svg', label: 'Discord' },
  bnet: { logo: 'bnet-logo-blue.svg', label: 'Battle.net' },
  google_oauth2: { logo: 'google-logo.svg', label: 'Google' },
  patreon: { logo: 'patreon-logo.svg', label: 'Patreon' },
  wcl: { logo: 'wcl-logo.png', label: 'Warcraft Logs' },
}

const LoginFailure = () => {
  const { t } = useTranslation()
  const {
    data: { user },
  } = usePageInfo()
  const [searchParams] = useSearchParams()

  const type = searchParams.get('message')
  const strategy = searchParams.get('strategy')

  if (!infoByStrategy[(strategy || '') as AuthStrategy])
    return <Main>Something went wrong</Main>

  if (user && (strategy === 'bnet' || strategy === 'google_oauth2')) {
    return <Navigate to='/' />
  } else {
    return (
      <Main>
        <div className='mt-20 text-center flex flex-col items-center justify-center'>
          <h1 className='flex gap-6 items-center text-xl'>
            <img
              src={imageUrl(infoByStrategy[strategy as AuthStrategy]?.logo)}
              className='w-12'
            />
            <span>
              {t('errors.login.failure_page_title', {
                account: infoByStrategy[strategy as AuthStrategy]?.label,
              })}
            </span>
          </h1>
          <p className='mt-8 max-w-[30rem]'>{t(`errors.login.${type}`)}</p>
        </div>
      </Main>
    )
  }
}

export default LoginFailure
