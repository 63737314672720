import React from 'react'
import { imageUrl } from '../helpers'

interface Props {
  faction?: string
}

const iconStyles = 'w-6 h-6 border-2 border-[#666] rounded-md'

const FactionIcon = ({ faction }: Props) => {
  if (faction) {
    return (
      <img
        className={iconStyles}
        src={imageUrl(`${faction.toLowerCase()}-logo.png`)}
      />
    )
  } else {
    return <img className={iconStyles} src={imageUrl('classes/unknown.png')} />
  }
}

export default FactionIcon
