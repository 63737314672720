import { CharacterClass } from 'app/frontend/types/enums'
import { characterClasses } from 'app/frontend/types/enums/CharacterClass'
import React from 'react'
import { imageUrl } from '../helpers'

interface Props {
  characterClass?: CharacterClass
}

const iconStyles = 'w-6 h-6 border-2 border-[#666] rounded-md'

const CharacterClassIcon = ({ characterClass }: Props) => {
  if (characterClass && characterClasses.includes(characterClass)) {
    return (
      <img
        className={iconStyles}
        src={imageUrl(`classes/${characterClass.toLowerCase()}.jpeg`)}
      />
    )
  } else {
    return <img className={iconStyles} src={imageUrl('classes/unknown.png')} />
  }
}

export default CharacterClassIcon
