import { TFunction } from 'i18next'
import { useState } from 'react'

import { awaitPendingJob, displayErrorToast, premiumColors } from '.'
import { getEntityFromBlizzard } from '../api/search'
import { toast } from 'sonner'
import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import BnetLogoWhite from 'public/images/bnet-logo-white.svg?react'
import BnetLogoBlack from 'public/images/bnet-logo.svg?react'
import { useTheme } from '@/components/theme-provider'

export const usePrefetchedData = <T extends { path: string }>(
  type: 'Guild' | 'Community' | 'Team' | 'Character',
) => {
  const data = window.prefetched_data[type] as T | undefined

  if (data && window.location.pathname.startsWith(data.path)) {
    return data
  }
}

export const useSubscription = (user?: wowaudit.User) => {
  const subscription = user?.subscription
  const legacySubscription = user?.linkedAccounts.find(
    (a) =>
      a.displayType === 'Patreon' &&
      a.details.active &&
      a.details.pledge_tier !== 'Free',
  )

  const subscriptionActive =
    (subscription?.active && !!subscription.tierName) ||
    !!legacySubscription?.details.pledge_tier

  const subscriptionColor =
    premiumColors[
      subscription?.tierName ||
        legacySubscription?.details.pledge_tier ||
        'Free'
    ]

  const subscriptionName =
    (subscription?.active
      ? subscription.tierName
      : legacySubscription?.details.pledge_tier) || 'Free'

  return {
    subscription,
    legacySubscription,
    subscriptionActive,
    subscriptionName,
    subscriptionColor,
  }
}

// Extend with other sources that could also generate warnings
export const useActionable = (user?: wowaudit.User) => {
  return !!user?.characters.filter((ch) => ch.actionable).length
}

interface RefreshDataOptions {
  retriever: (id: number) => Promise<{ job_id: string }>
  id?: number
  successKey?: string
  callback?: () => void
  queryToInvalidate?: QueryKey
}

export const useRefreshData = (options: RefreshDataOptions) => {
  const { t } = useTranslation()
  const { retriever, id, successKey, callback, queryToInvalidate } = options
  const queryClient = useQueryClient()
  const [refreshing, setRefreshing] = useState(false)
  const [updateTriggeredAt, setUpdateTriggeredAt] = useState<Date>()

  const refreshData = () => {
    setRefreshing(true)
    setUpdateTriggeredAt(new Date())

    retriever(id as number)
      .then(async (response) => {
        awaitPendingJob(response.job_id, (result) => {
          if (result.error) {
            displayErrorToast(result.error, t)
          } else {
            if (successKey) toast.success(t(successKey))
            if (callback) callback()
            if (queryToInvalidate) {
              queryClient.invalidateQueries({ queryKey: queryToInvalidate })
            }
          }
          setRefreshing(false)
        })
      })
      .catch((response) => {
        displayErrorToast(response, t)
        setRefreshing(false)
      })
  }

  return {
    refreshData,
    refreshing,
    updateTriggeredAt,
  }
}

export const imageUrl = (path: string) => {
  const assetHost = window.page_info.assetHost
  return `${assetHost}/images/${path}`
}

export const useLogo = () => {
  const { currentScheme } = useTheme()
  const Logo = currentScheme === 'dark' ? BnetLogoWhite : BnetLogoBlack

  return { Logo }
}

export const useBlizzardApi = <TBlizzardEntity>(
  type: 'Character' | 'Guild',
  t: TFunction,
) => {
  const [_1, _2, uriRegion, uriRealm, uriName] =
    window.location.pathname.split('/')

  const [blizzardInfo, setBlizzardInfo] = useState<TBlizzardEntity>()
  const [blizzardStatus, setBlizzardStatus] = useState<
    '' | 'pending' | 'not-found' | 'found'
  >('')

  const searchBlizzard = () => {
    setBlizzardStatus('pending')
    getEntityFromBlizzard(uriName, uriRealm, uriRegion, type)
      .then((response) => {
        if (response.length) {
          setBlizzardInfo(response[0])
          setBlizzardStatus('found')
        } else {
          setBlizzardStatus('not-found')
        }
      })
      .catch((response) => {
        displayErrorToast(response, t)
        setBlizzardStatus('')
      })
  }

  return {
    uriRegion,
    uriRealm,
    uriName,
    blizzardInfo,
    blizzardStatus,
    searchBlizzard,
  }
}
